<template>
  <div class="vg_wrapper">
    <el-card v-loading='isLoading'>
      <div class="vg_button_group">
        <span v-if="isShow === true">
          <el-button
            type="success"
            @click="openEdit"
            size="small"
            :disabled="!btn.edit"
            >编辑</el-button
          >
        </span>
        <span v-if="isShow === false"> 
          <el-button type="primary" @click="submit('flowForm')" size="small"
            >保存</el-button
          >
          <el-button @click="cancel" size="small">取消</el-button>
        </span>
      </div>
      <div>
        <el-row class="vd_align_center">
          <el-col :md="8">
            审批模块：<el-input
              v-model="flowForm.perm_name"
              show-word-limit
              disabled
              class="vg_width_50p"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :md="8">
            所属账套：
            <el-input
              v-model="flowForm.acct_name"
              show-word-limit
              disabled
              class="vg_width_50p"
              size="mini"
            ></el-input>
          </el-col>
          <el-col :md="8">
            <el-switch
              class="vg_ml_16"
              :disabled="isShow"
              v-model="flowForm.appr_shelved"
              :active-value="1"
              :inactive-value="0"
              active-text="开启"
              inactive-text="关闭"
            ></el-switch>
          </el-col>
        </el-row>
      </div>
      <!--    节点-->
      <el-form
        ref="nodeForm"
        :model="nodeForm"
        label-width="120px"
        size="mini"
        :rules="rules"
      >
        <div class="vg_button">
          <el-button
            type="primary"
            plain
            size="small"
            @click="addRow()"
            :disabled="addDisabled"
            >添加</el-button
          >
          <span class="vd_remark">（一次只可新增一行）</span>
        </div>
        <el-table :data="nodeForm.node_list" border class="vg_mt_16">
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="节点名" width="320">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'node_list.' + scope.$index + '.appr_node_name'"
                :rules="[{ required: true }]"
              >
                <el-input
                  v-model="nodeForm.node_list[scope.$index].appr_node_name"
                  :disabled="nodeForm.node_list[scope.$index].isShowNode"
                  show-word-limit
                  placeholder="请填写节点名（必填）"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="审批账号" v-if="isDeath">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'node_list.' + scope.$index + '.appr_node_stff_names'"
              >
                <el-input
                  :disabled="true"
                  v-model="
                    nodeForm.node_list[scope.$index].appr_node_stff_names
                  "
                >
                  <template slot="append">
                    <el-link
                      class="vg_cursor"
                      @click="selPer(scope)"
                      :disabled="scope.row.isShowNode"
                      >选择</el-link
                    >
                  </template>
                </el-input>
                <!-- <select-input
                  :selTableData="selTableData"
                  :tableHeader="staffTableHeader"
                  @getSelTableList="getSelTableList"
                  @openSelect="openSelect"
                  @setRowSelect="setRowSelect"
                  :inputVal="nodeForm.node_list[scope.$index].appr_node_stff_names"
                  :disabled="nodeForm.node_list[scope.$index].isShowNode"
                  :rowSelect = "rowSelect"
                  :isShowCol="true"
                  >
              </select-input> -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-row
                v-if="!nodeForm.node_list[scope.$index].btnShow"
                type="flex"
                justify="space-around"
              >
                <el-col :md="12">
                  <el-link
                    type="success"
                     class="vg_pointer"
                    @click="handleEdit(scope.$index, scope.row)"
                    :disabled="saveChanges"
                    >修改</el-link
                  >
                </el-col>
                <el-col :md="12">
                  <el-link
                    type="danger"
                    @click="remove(scope.$index, scope.row)"
                    :disabled="saveChanges"
                     class="vg_pointer"
                    >移除</el-link
                  >
                </el-col>
              </el-row> 
              <el-col :md="12">
                <el-link
                type="primary"
                @click="handleSave(scope.$index, scope.row)"
                v-if="nodeForm.node_list[scope.$index].btnShow"
                class="vg_pointer"
                >保存</el-link
              >
              </el-col>
              
              <el-col :md='12'>
                 <el-link
                    type="danger"
                    @click="remove1(scope.$index, scope.row)"
                     v-if="nodeForm.node_list[scope.$index].btnShow"
                     class="vg_pointer"
                    >移除</el-link
                  >
              </el-col>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <!--    流-->
      <div class="vg_button">
        <el-button
          type="primary"
          plain
          size="small"
          @click="addFlowRow()"
          :disabled="isShow"
          >添加</el-button
        >
        <el-button
          type="danger"
          plain
          size="small"
          @click="delFlowRow()"
          :disabled="isShow"
          >删除</el-button
        >
      </div>
      <el-form
        ref="flowForm"
        :model="flowForm"
        label-width="120px"
        size="mini"
        :disabled="isShow"
        :rules="rules"
      >
        <el-table
          :data="flowForm.appr_flow_list"
          border
          class="vg_mt_16"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="48"
            align="center"
          ></el-table-column>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="起始节点">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="
                  'appr_flow_list.' + scope.$index + '.appr_flow_this_node'
                "
                :rules="[{ required: true }]"
              >
                <el-select
                  v-model="
                    flowForm.appr_flow_list[scope.$index].appr_flow_this_node
                  "
                  show-word-limit
                  placeholder="请选择起始节点"
                >
                  <el-option
                    v-for="(item) in thisNodeList"
                    :key="item.appr_flow_this_node"
                    :label="item.appr_flow_this_node_name"
                    :value="item.appr_flow_this_node"
                    :disabled='scope.$index!=0?false :item.appr_flow_this_node ==-1?false:true'
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="'appr_flow_list.' + scope.$index + '.appr_flow_action'"
                :rules="[{ required: true }]"
              >
                <el-select
                  v-model="
                    flowForm.appr_flow_list[scope.$index].appr_flow_action
                  "
                  show-word-limit
                  placeholder="请选择操作"
                >
                  <el-option label="提交" :value="1"></el-option>
                  <el-option label="通过" :value="2"></el-option>
                  <el-option label="拒绝" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="下一节点">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="
                  'appr_flow_list.' + scope.$index + '.appr_flow_next_node'
                "
                :rules="[{ required: true }]"
              >
                <el-select
                  v-model="
                    flowForm.appr_flow_list[scope.$index].appr_flow_next_node
                  "
                  show-word-limit
                  placeholder="请选择下一节点"
                >
                  <el-option
                    v-for="item in nextNodeList"
                    :key="item.appr_flow_next_node"
                    :label="item.appr_flow_next_node_name"
                    :value="item.appr_flow_next_node"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="条件">
            <template slot-scope="scope">
              <el-form-item
                label-width="0"
                :prop="
                  'appr_flow_list.' + scope.$index + '.appr_flow_condition'
                "
              >
                <el-input
                  v-model="
                    flowForm.appr_flow_list[scope.$index].appr_flow_condition
                  "
                  show-word-limit
                  placeholder="请填写条件语句（必须以and开头）"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>

    <el-dialog
    title="审批账号列表："
      :visible.sync="selDialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form ref="innerFormRef" :model="innerForm" v-loading="innerLoading">
        <el-row>
          <el-col :md="6">
            <el-form-item
              label="姓名："
              label-width="100px"
              size="small"
              prop="stff_name"
            >
              <el-input
                v-model="innerForm.stff_name"
                clearable
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6">
            <el-form-item
              label="账号："
              label-width="100px"
              size="small"
              prop="user_name"
            >
              <el-input
                v-model="innerForm.user_name"
                clearable
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
          </el-col>
             <el-col :md="6">
            <el-form-item
              label="账套："
              label-width="100px"
              size="small"
              prop="acct_id"
            >
              <el-select
                v-model="innerForm.acct_id"
                filterable
                placeholder="请选择审批账套"
                clearable
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-form>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="innerSearch"
              class="vg_ml_16"
              >查询</el-button
            >
            <el-button
              size="small"
              type="info"
              icon="el-icon-refresh-right"
              @click="innerSearchO"
              class="vg_ml_16"
              >刷新</el-button
            >
          </el-form>
        </el-row>
    

        <el-row class="vg_pt_16">
          <el-form-item label-width="40px" size="small">
            <el-button type="danger" plain @click="canHandle"
              >取消选择</el-button
            >
            <el-button type="primary" plain @click="detHandle"
              >确定选择</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <el-table
        v-loading="innerLoading"
        max-height="350px"
        ref="selTabRef"
        class="diaTable"
        :data="selTableData"
        border
        @select-all="handleSelectionChangeT"
        @select='handelselectChangeO'
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="stff_name" label="姓名"></el-table-column>
        <el-table-column prop="user_name" label="账号"></el-table-column>
        <el-table-column
          prop="acct_no"
          label="所属账套"
          show-overflow-tooltip
        ></el-table-column>
         <el-table-column
          prop="cptt_name"
          label="公司"
          show-overflow-tooltip
        ></el-table-column>
        
         <el-table-column prop="dept_name" label="部门"></el-table-column>
        <el-table-column prop="dept_team_name" label="小组">
          <template slot-scope="scope">
            <span v-if="scope.row.dept_team_name">{{
              scope.row.dept_team_name
            }}</span>
            <span v-else class="vg_9f9a9a">暂无小组</span>
          </template>
        </el-table-column> 
       
       
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { apprAPI } from "@api/modules/appr";
import { staffTableHeader } from "@/views/component/tableHeader/staffTableHeader";
import { userAPI } from "@/api/modules/user";
import { acctAPI } from "@api/modules/acct";
import selectInput from "@/views/component/selectInput";
export default {
  name: "ApprEdit",
  components: {
    // selectInput
  },
  data() {
    return {
      isShow: true,
      btn: {},
      nodeForm: {
        node_list: [],
      },
      flowForm: {
        perm_name: "",
        appr_shelved: 0,
        appr_flow_list: [],
        appr_flow_copy: [],
      },
      thisNodeList: [],
      nextNodeList: [],
      key: 0,
      keyCode: 0,
      rowSelect: [],
      secApprId: null,
      appr_id: null,
      
      selectionsList: [],
      staffTableHeader: [],
      stffForm: [],
      newNAME: "",
      stffNames: [],
      stffIds: [],
      addDisabled: true,
      fbDisabled: true,
      index: null,
      isDeath: false,
      isSaveAll: true,
      isAddSelName: false,
      saveChanges: true,
      selDialogVisible: false,
      rules: {},
      copyForm: [],
      innerForm: {
        stff_name: "",
        user_name: "",
        acct_id: "",
      },
      acctList: [],
      isLoading:true,
      innerLoading: true,
      copyArr:[],  //选中后 的 id 
      selectionTabs:[], //所有审批人信息
      selTableData: [], // 每次搜索展示内容 
      detList:[],//之前选中
      detList1:[],
    };
  },
  watch: {
    "flowForm.appr_flow_list": {
      handler: function (newVal) {
        newVal.forEach((item) => {
          if (item.appr_flow_this_node === -1) {
            item.appr_flow_action = 1;
          }
        });
      },
      deep: true,
    },
  },
  created() {
    this.initData();
    this.isDeath = true;
  },
  methods: {
    initData() {
      if (this.$route.query.form_id) {
        this.getFlowInfo();
        this.getSelectTable();
        this.staffTableHeader = staffTableHeader;
        this.appr_id = this.$route.query.form_id;
        this.getNodeInfo();
        this.getAcct();
      }
    },
    // //启用编辑
    openEdit() {
      this.isShow = false;
      this.saveChanges = false;
      this.addDisabled = false;
      this.fbDisabled = false;
    },
    // 撤销编辑
    cancel() {
      this.$confirm("是否撤销编辑?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then((res) => {
          this.isShow = true;
          this.saveChanges = true;
          this.addDisabled = true;
          this.fbDisabled = true;
          this.thisNodeList = [];
          this.nextNodeList = [];
          this.initData();
          this.$message({
            type: "info",
            message: "已撤销编辑!",
          });
        })
        .catch((e) => e);
    },
    //获取节点信息
    getNodeInfo() {
      this.nodeForm.appr_id = this.$route.query.form_id;
      get(apprAPI.getApprNodesByApprId, { appr_id: this.nodeForm.appr_id })
        .then((res) => {
          if (res.data.code === 0) {
            const nodeList = res.data.data;
            nodeList.map((item) => {
              const thisNodeObj = {
                appr_flow_this_node: item.appr_node_id,
                appr_flow_this_node_name: item.appr_node_name,
              };
              const nextNodeObj = {
                appr_flow_next_node: item.appr_node_id,
                appr_flow_next_node_name: item.appr_node_name,
              };
              this.thisNodeList.push(thisNodeObj);
              this.nextNodeList.push(nextNodeObj);
            });
            const thisStartNodeObj = {
              appr_flow_this_node: -1,
              appr_flow_this_node_name: "开始",
            };
            this.thisNodeList.push(thisStartNodeObj);
            const nextStartNodeObj = {
              appr_flow_next_node: -1,
              appr_flow_next_node_name: "开始",
            };
            const endNodeObj = {
              appr_flow_next_node: 0,
              appr_flow_next_node_name: "结束",
            };
            this.nextNodeList.push(nextStartNodeObj);
            this.nextNodeList.push(endNodeObj);
            this.nodeForm.node_list = res.data.data;
            for (let i = 0; i < this.nodeForm.node_list.length; i++) {
              if (this.nodeForm.node_list[i].appr_node_stff_names === null) {
                this.nodeForm.node_list[i].appr_node_stff_names = "外部审批方"; 
              }
            }
            this.nodeForm.node_list.forEach((item) => {
              item.k = this.key;
              item.isShowNode = true;
              item.btnShow = false;
              item.appr_node_user_id_list = this.helper.toArray(
                item.appr_node_user_ids
              );
              this.key++;
            });
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取流信息
    getFlowInfo() {
      get(apprAPI.getApprById, { appr_id: this.$route.query.form_id })
        .then((res) => {
          if (res.data.code === 0) {
            this.btn = res.data.data.btn;
            this.flowForm = res.data.data.form;
            this.flowForm.appr_flow_list.forEach((item) => {
              item.k = this.keyCode;
              this.keyCode++;
              item.isShowFlow = true;
            });
            this.flowForm.appr_flow_copy = Object.assign(
              [],
              this.flowForm.appr_flow_list
            );
            this.copyForm = Object.assign(
              this.copyForm,
              this.flowForm.appr_flow_list
            );
            setTimeout(()=>{
            this.isLoading = false
            },500)
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => { 
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取传递selTableData
    getSelectTable() {
      get(userAPI.getAllUsersV1, this.innerForm)
        .then((res) => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
            if(this.selectionTabs.length==0){
              this.selectionTabs = res.data.data
            }
            setTimeout(() => {
              this.innerLoading = false;
            }, 50);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            setTimeout(() => {
              this.innerLoading = false; 
            }, 500);
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //node列表添加单行
    addRow() {
      this.addDisabled = true;
      this.saveChanges = true;
      this.newNAME = "";
      let item = {
        k: this.key,
        appr_node_name: "",
        appr_node_id: null,
        appr_id: this.appr_id,
        appr_node_stff_names: this.newNAME,
        appr_node_user_id_list: [],
        isShowNode: false,
        btnShow: true,
      };
      this.isAddSelName = true;
      if (item.btnShow === true) {
        this.isSaveAll = false;
      }
      this.rowSelect = [];
      this.nodeForm.node_list.push(item);
    },
    openSelect(val) {
      if (this.isAddSelName) {
        this.rowSelect = [];
      }
    },
    // 选择stfflist
    getSelTableList(val) {
      this.stffForm = val;
      if (this.isAddSelName === true) {
        this.addSelectName();
      } else {
        const stffIds = [];
        const stffNames = [];
        this.stffForm.forEach((item) => {
          stffIds.push(item.user_id);
          stffNames.push(item.stff_name);
        });
        this.stffIds = stffIds;
        this.newNAME = stffNames.toString();
        this.nodeForm.node_list.forEach((item, index) => {
          if (this.secApprId === item.appr_node_id) {
            item.appr_node_stff_names = this.newNAME;
            item.appr_node_user_id_list = this.stffIds;
          }
        });
      }
    },
    addSelectName() {
      const stffIds = [];
      const stffNames = [];
      this.stffForm.forEach((item) => {
        stffIds.push(item.user_id);
        stffNames.push(item.stff_name);
      });
      this.stffIds = stffIds;
      this.newNAME = stffNames.toString();
      this.nodeForm.node_list.forEach((item) => {
        if (this.key === item.k) {
          item.appr_node_stff_names = this.newNAME;
          item.appr_node_user_id_list = this.stffIds;
        }
      });
      this.isAddSelName = false;
    },
    setRowSelect(val) {
      if (val.length > 0) {
        this.rowSelect = val;
      }
    },
    //单行修改
    handleEdit(index, row) {
      this.addDisabled = true;
      this.saveChanges = true;
      row.isShowNode = false;
      row.btnShow = true;
      this.isAddSelName = false;
      this.rowSelect = row.appr_node_user_id_list;
      this.secApprId = row.appr_node_id;
      if (row.btnShow === true) {
        this.isSaveAll = false;
      } else {
        this.isSaveAll = true;
      }
      this.$set(this.nodeForm.node_list, "isShowNode", false); // 渲染无法检测，updata生命周期无法更新
    },
    //单行移除
    remove(index, row) {
      let flag = this.flowForm.appr_flow_list.some(item =>{
         return row.appr_node_id==item.appr_flow_next_node || row.appr_node_id==item.appr_flow_this_node
      })
      if(flag){
        return this.$message.warning('该审批节点已在审批流使用,无法删除')
      }
      this.$confirm("确定移除？", "提示", {  
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        post(apprAPI.destroyApprNodeById, { appr_node_id: row.appr_node_id })
          .then((res) => {
            if (res.data.code === 0) {
              this.thisNodeList = [];
              this.nextNodeList = [];
              this.getNodeInfo();
              this.addDisabled = false;
            } else {
              let mg = res.data.msg;
              let tp = "error";
              this.$message({ message: mg, type: tp });
              this.thisNodeList = [];
              this.nextNodeList = [];
              this.getNodeInfo();
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }).catch(()=>{});
    },
    remove1(index, row){
      this.$confirm("确定移除？", "提示", {  
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.isSaveAll = true 
        this.addDisabled = false;
        this.saveChanges = false
        this.nodeForm.node_list.splice(index,1)
      })
      .catch(res =>{

      })
      
    },
    handleSave(index, row) { 
      if (
        row.appr_node_name === '' ||
        row.appr_node_user_id_list.length === 0
      ) {
        this.$message({
          type: "warning",
          message: "节点名或审批人不能为空！",
        });
        this.isSaveAll = false;
      } else {
        this.doSave(index, row);
      }
    },
    //单行保存
    doSave(index, row) {
      this.$confirm("确定保存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const apprNodeBody = {};
          apprNodeBody.appr_node_id = row.appr_node_id;
          apprNodeBody.appr_id = row.appr_id;
          apprNodeBody.appr_node_name = row.appr_node_name;
          apprNodeBody.appr_node_user_id_list = row.appr_node_user_id_list;
          post(apprAPI.editApprNode, apprNodeBody)
            .then((res) => {
              if (res.data.code === 0) {
                row.btnShow = false;
                this.$set(this.nodeForm.node_list, "btnShow", false);
                let mg = "节点保存成功！";
                let tp = "success";
                this.$message({ message: mg, type: tp });
                this.thisNodeList = [];
                this.nextNodeList = [];
                this.getNodeInfo();
                this.isSaveAll = true;
                this.saveChanges = false;
                this.addDisabled = false;
                this.newNAME = "";
                this.stffIds = [];
              } else {
                let mg = res.data.msg;
                let tp = "error";
                this.$message({ message: mg, type: tp });
                this.thisNodeList = [];
                this.nextNodeList = [];
                this.getNodeInfo();
                row.btnShow = false;
                this.isSaveAll = false;
                this.$set(this.nodeForm.node_list, "btnShow", false);
                this.addDisabled = false;
              }
            })
            .catch((res) => {
              let mg = res.data.msg;
              let tp = "error";
              this.$message({ message: mg, type: tp });
            });
        })
        .catch((e) => e);
    },
    //流列表添加行
    addFlowRow() {
      let item = {
        k: this.keyCode,
        appr_flow_this_node: null,
        appr_flow_action: null,
        appr_flow_next_node: null,
        appr_flow_condition: "",
        destroy_flag: null,
      };
      this.keyCode = this.keyCode + 1;
      this.flowForm.appr_flow_list.push(item);
      this.selectionsList = [];
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //流列表多选删除
    delFlowRow() {
      if (this.selectionsList.length <= 0) {
        return this.$message.warning("至少选择一条数据");
      }
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.flowForm.appr_flow_list.forEach((item) => {
          if (item.k === this.selectionsList[i].k) {
            let currentIndex = this.flowForm.appr_flow_list.indexOf(item);
            this.flowForm.appr_flow_list.splice(currentIndex, 1);
            this.flowForm.appr_flow_copy.forEach((item) => {
              if (item.k === this.selectionsList[i].k) {
                item.destroy_flag = 1;
              }
            });
          }
        });
      }
    },
    //提交整体表单
    submit(formName) {
      if(this.isSaveAll === false){
        this.$message({
          type: "warning",
          message: "请先保存配置节点！",
        });
      }else{
        this.$confirm("确定提交？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.isLoading = true
                this.saveInfo();
              } else {
                console.log("error submit!");
                return false;
              }
            });
          })
        .catch((e) => e);
      }

      
    },
    // 整体保存
    saveInfo() {
      let flowSaveForm = {};
      flowSaveForm = Object.assign(flowSaveForm, this.flowForm);
      flowSaveForm.appr_flow_list = Object.assign(
        flowSaveForm.appr_flow_list,
        this.flowForm.appr_flow_copy
      );
      post(apprAPI.editAppr, flowSaveForm)
        .then((res) => {
          if (res.data.code === 0 && this.isSaveAll === true) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.getFlowInfo();
            this.isShow = true;
            this.saveChanges = true;
            this.addDisabled = true;
          } else if (res.data.code !== 0) {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
          setTimeout(()=>{
            this.isLoading = false 
          },500)
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
   
   
    canHandle() {
      this.$refs.selTabRef.clearSelection();
      this.detList1 = []
      this.selDialogVisible = false
    }, 
    
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.selTableData.forEach((item, index) => {
            if (row === item.user_id) {
              this.$nextTick(() => { 
                this.$refs.selTabRef.toggleRowSelection(
                  this.selTableData[index],true   
                );
              });
            }
          });
        });
      } else {
        this.$refs.selTabRef.clearSelection();
      }
    },
 
    //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
        });
    },
    handleClose() {
      this.$refs.innerFormRef.resetFields();
      this.selDialogVisible = false;
      this.getSelectTable();
    },
    //单个选
    handelselectChangeO(val,i){  
      let temp = i
      let arr = []
      if(val.length>0){
        if(this.detList1.indexOf(temp.user_id) !== -1){
          setTimeout(()=>{
            this.$refs.selTabRef.toggleRowSelection(temp,false);
            this.detList1.splice(this.detList1.indexOf(temp.user_id),1)
            for(let i=0;i<this.detList1.length;i++){
              if(arr.indexOf(this.detList1[i])===-1){
                arr.push(this.detList1[i])
              }
            }
            this.detList1 = arr
            this.detList = val
          },600)
        }else{
          setTimeout(() => {
            this.$refs.selTabRef.toggleRowSelection(temp,true);
              this.detList1.push(temp.user_id)
              for(let i=0;i<this.detList1.length;i++){
                if(arr.indexOf(this.detList1[i])===-1){
                  arr.push(this.detList1[i])
                }
              }
              this.detList1 = arr
              this.detList = val
            }, 600);
          }
        }else{
          for(let j=0;j<this.selTableData.length;j++){
            if (this.detList1.indexOf(this.selTableData[j].user_id) !== -1) {
              this.detList1.splice(this.detList1.indexOf(this.selTableData[j].user_id),1)
            }
          }
          for(let i=0;i<this.detList1.length;i++){
            if(arr.indexOf(this.detList1[i])===-1){
              arr.push(this.detList1[i])
            }
          }
          this.detList1 = arr
          this.detList = val
        }
    },
    //全选
   handleSelectionChangeT(val) {
     if(val.length>0){
      this.detList1 = [];
      for(let j=0;j<this.selTableData.length;j++){
        this.detList1.push(this.selTableData[j].user_id)
      }
      this.detList = val
     }else{
       this.detList1 = []
       this.detList = val
     }
    },
    //确定选择
    detHandle() {
      this.innerForm={
        stff_name: "",
        user_name: "",
        acct_id: "",
      }
      this.getSelectTable()
      setTimeout(() => {
        let str = ''
        let temp = []
        if(this.detList1.length===0){
          return this.$message.warning('至少选择一条数据')
        }else if(this.detList1.length === 1){
          str = this.detList[0].stff_name
        }else{
          for(let j=0;j<this.detList1.length;j++){
            for(let i=0;i<this.selTableData.length;i++){
              if(this.detList1[j] === this.selTableData[i].user_id){
                temp.push(this.selTableData[i].stff_name)
              }
            }
          }
          str = temp.join(',')
        }
        this.nodeForm.node_list[this.index].appr_node_stff_names = str;
        this.nodeForm.node_list[this.index].appr_node_user_id_list = JSON.parse(JSON.stringify(this.detList1));
        this.selDialogVisible = false;
      }, 500);
    },
     //选择审批人  
    selPer(scope) {
      this.detList = JSON.parse(JSON.stringify(this.nodeForm.node_list[scope.$index].appr_node_user_id_list))
      this.detList1 = JSON.parse(JSON.stringify(this.detList))
      this.index = scope.$index;
      let dataparams = [];
      this.nodeForm.node_list[scope.$index].appr_node_user_id_list.forEach(
        (item1) => {
          this.selTableData.forEach((item2) => {
            if (item1 === item2.user_id) {
              dataparams.push(item1);
            }
          });
        }
      );
      setTimeout(() => {
        this.toggleSelection(dataparams);
      }, 100);
      this.innerLoading = true;
      setTimeout(() => {
        this.innerLoading = false;
      }, 300);
      this.selDialogVisible = true;

    },
    // 刷新按钮
    innerSearchO(){
      this.innerForm.acct_id = null;
      this.innerForm.user_name = null;
      this.innerForm.stff_name = null;
      this.innerSearch()
    },
       //审批人表单搜索
    innerSearch() { 
      this.innerLoading = true;
      this.getSelectTable();
      setTimeout(()=>{  
        for(let i=0;i<this.detList1.length;i++){
          for(let j=0;j<this.selTableData.length;j++){
            if (this.detList1[i] === this.selTableData[j].user_id) {
              this.$nextTick(() => { 
                setTimeout(()=>{
                  this.$refs.selTabRef.toggleRowSelection(
                  this.selTableData[j]
                );
                },100)
              });
            }
          }
        }
      },500)
    },  
  },
};
</script>

<style scoped lang="scss">
.vd_align_center {
  display: flex;
  align-items: center;
}
.vd_remark {
  color: $color-info;
}
.diaTable {
  margin-top: 25px;
}
</style>